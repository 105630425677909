import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

let urutan = 0;

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      pilihBalai: null,
      pilihProject: null,
      pilihSatker: null,
      isActive: [],
      listBalai: [],
      listProject: [],
      listStatker: [],
      listDataPaket: [],
      listTahun:[],
      listGiat: [],
      listRegister: [],
      listKategori: [],
      listKodeBeban:[],
      setDefaultTahun:new Date().getFullYear(),
      tbData: [],
      pgMin: 0,
      pgMax: 0,
      timeout: null,
      setDefaultBalai: "all",
      setDefaultSatker: "all",
      setDefaultProyek: "hasLoanOnly",

      isLoader: true,
      noUrut: 1,
      tanggalEmon: null,
      columnTab: [
        // {'text': 'Kode','value': 'c_1'},
        // {'text': 'Kegiatan/Output/Paket/Sub Paket','value': 'c_2'},
        // {'text': 'Target Volume','value': 'c_3'},
        // {'text': 'Lokasi','value': 'c_4'},
        // {'text': 'Jenis Paket','value': 'c_5'},
        { text: "Metode Pemilihan", value: "c_6" },
        // {'text': 'Kode Proyek','value': 'c_7'},
        { text: "Kode Register", value: "c_8" },
        { text: "Kode KPPN", value: "c_9" },
        { text: "Kode Beban", value: "c_10" },
        // {'text': 'Pagu (Rp Ribu)','value': 'c_11'},
        // {'text': 'Realisasi (Rp Ribu)','value': 'c_12'},
        // {'text': '% KEU','value': 'c_13'},
        // {'text': '% FISIK','value': 'c_14'}
        { text: "Isian Prognosis (Rp Ribu)", value: "c_15" },
        { text: "Isian Prognosis (%)", value: "c_16" },
        //{ text: "Prognosis (Rp Ribu)", value: "c_17" },
        //{ text: "Prognosis (%)", value: "c_18" },
        { text: "Keterangan", value: "c_19" },
        { text: "Register", value: "c_21" },
      ],
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.G_GetRef("balai").then((data) => {
      this.listBalai = data;

      if (this.getUser.userGroup == "balai") {
        this.setDefaultBalai = this.getUser.kdbalai;
        // this.$refs.balai.setValue(this.getUser.kdbalai)
      } else if (this.getUser.userGroup == "satker") {
        this.setDefaultBalai = this.getUser.kdbalai;

        // this.$refs.balai.setValue(this.getUser.kdbalai)
      } else {
        this.listBalai.unshift({
          text: "Semua Balai",
          value: "all",
        });

        this.setDefaultBalai = "all";

        // this.$refs.balai.setValue('all')
      }
    });

    this.G_GetRef('beban').then(data => {
      this.listKodeBeban = data;
        this.listKodeBeban.unshift({
        'text': 'Semua Kode Beban',
        'value': 'all'
      })

    });

    this.G_GetRef("satker").then((data) => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {
        // this.$refs.satker.setValue(this.getUser.kdsatker)
        this.setDefaultSatker = this.getUser.kdsatker;
      } else {
        this.listStatker.unshift({
          text: "Semua Satker",
          value: "all",
        });

        // this.$refs.satker.setValue('all')
        this.setDefaultSatker = "all";
      }
    });

    this.G_GetRef("proyek").then((data) => {
      this.listProject = data;

      if (this.getUser.userGroup != "proyek") {
        this.listProject.unshift(
          //   {
          //   'text': 'Semua Proyek',
          //   'value': 'all'
          // },
          {
            text: "Paket Terdapat Data Proyek",
            value: "hasProyek",
          },
          {
            text: "Semua Paket Loan",
            value: "hasLoanOnly",
          },
          {
            text: "Semua Paket Sbsn",
            value: "hasSbsnOnly",
          },
          {
            text: "Semua Paket Rupiah",
            value: "hasRupiahOnly",
          }
        );

        this.setDefaultProyek = "hasLoanOnly";

        // this.$refs.projek.setValue('hasLoanOnly')
      } else {
        this.setDefaultProyek = this.getUser.projekId;
        // this.$refs.projek.setValue(this.getUser.projekId)
      }
    });

    this.G_GetRef("register").then((data) => {
      this.listRegister = data;
      this.listRegister.unshift({
        text: "Semua Register",
        value: "all",
      });
    });
    this.G_GetRef("kategori").then((data) => {
      this.listKategori = data;
      this.listKategori.unshift({
        text: "Semua Kategori",
        value: "all",
      });
    });
  
    this.G_GetAny('progres/tahun').then(res => {
      this.listTahun = res.data.content;
      this.setDefaultTahun = this.listTahun[0];

      this.G_GetAny('progres/giat?tahun='+this.setDefaultTahun).then(res => {

        let listData = [];
        let arrSub = {};
        let resData = res.data.content;
  
        resData.forEach((v) => {
          arrSub = {};
          arrSub["text"] = v.nmgiat;
          arrSub["value"] = v.kdgiat;
  
          listData.push(arrSub);
        })
  
        this.pageLength = listData.length
  
        this.listGiat = listData;
      });
    });

    // this.$nextTick(() => {
    this.cariDataInit();
    // this.$refs.colomTable.selectAll()
    // })
  },
  components: {
    myTable,
  },
  methods: {
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }
    },
    cariDataInit() {

      $("#tableShow").show();

      this.isLoader = true;
      this.loadingTb = true;
      let filter = "";

      filter += "&includeRegister=false";
      filter += "&includeKppn=false";
      filter += "&includeBeban=false";
      filter += "&includeMetode=false";
      filter += "&hasLoanOnly=true";

      this.G_GetAny("progres/prognosis?tahun="+this.setDefaultTahun + filter).then((res) => {
        this.tanggalEmon = res.data.tanggalEmon;
        this.listDataPaket = res.data.content;

        this.loadingTb = false;
        this.isLoader = false;

        this.$nextTick(() => {
          let selectedColumn = [6, 8, 9, 10, 15, 16, 19, 21];
          selectedColumn.forEach((v) => {
            $(".c_" + v).hide();
          });
        });
      });
    },
    cariData() {
      // $('#tableShow').hide()
      this.isLoader = true;
      // this.$refs.giat.selectAll()
      urutan = 0;
      let myForm = document.getElementById("formCari");
      let formData = new FormData(myForm);

      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      let filter = "";


      if (this.$refs.balai.getValue()) {
        if (this.$refs.balai.getValue() != "all") {
          filter += "&kdbalai=" + this.$refs.balai.getValue();
        }
      }
      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter += "&kdsatker=" + this.$refs.satker.getValue();
        }
      }
      if (this.$refs.projek.getValue()) {
        if (this.$refs.projek.getValue() != "all") {
          if (this.$refs.projek.getValue() == "hasProyek") {
            filter += "&hasProyekOnly=true";
          } else if (this.$refs.projek.getValue() == "hasLoanOnly") {
            filter += "&hasLoanOnly=true";
          } else if (this.$refs.projek.getValue() == "hasSbsnOnly") {
            filter += "&hasSbsnOnly=true";
          } else if (this.$refs.projek.getValue() == "hasRupiahOnly") {
            filter += "&hasRupiahOnly=true";
          } else {
            filter += "&proyek=" + this.$refs.projek.getValue();
          }
        }
      }
      if (this.$refs.giat.getValue()) {
        filter += "&kdgiat=" + this.$refs.giat.getValue();
      }
      if (object.beban) {
        filter += "&beban=" + object.beban;
      }
      if (this.pgMin > 0) {
        filter += "&pgMin=" + this.pgMin * 1000;
      }
      if (this.pgMax > 0) {
        filter += "&pgMax=" + this.pgMax * 1000;
      }
      if (this.$refs.kdkategori.getValue()) {
        filter += "&kdkategori=" + this.$refs.kdkategori.getValue();
      }
      if (object.kdkppn) {
        filter += "&kdkppn=" + object.kdkppn;
      }

      // let filterSelect = object.colomTable

      let filterSelect = this.$refs.colomTable.getValue();

      if (filterSelect) {
        // filterSelect = filterSelect.split(",");
        filterSelect.forEach((v) => {
          filter += v == 8 ? "&includeRegister=true" : "&includeRegister=false";
          filter += v == 9 ? "&includeKppn=true" : "&includeKppn=false";
          filter += v == 10 ? "&includeBeban=true" : "&includeBeban=false";
          filter += v == 6 ? "&includeMetode=true" : "&includeMetode=false";
        });
      } else {
        filter += "&includeRegister=false";
        filter += "&includeKppn=false";
        filter += "&includeBeban=false";
        filter += "&includeMetode=false";
      }

      if (this.$refs.form.validate()) {
        // this.$refs.btnAct.load(true)
        this.G_GetAny("progres/prognosis?tahun="+this.setDefaultTahun+ filter).then((res) => {
          this.tanggalEmon = res.data.tanggalEmon;
          this.isLoader = false;

          // this.G_GetAny('progres/per-paket/rollup?kdbalai=Balai Besar Wilayah Sungai Cimanuk-Cisanggarung&g=31,15,1,0&tahun=2021').then(res => {

          this.listDataPaket = res.data.content;
          $("#tableShow").fadeIn();
          // this.$refs.btnAct.load(false)
          this.loadingTb = false;

          this.$nextTick(() => {
            // if(object.colomTable){
            // $('*[class^="c_"]').hide()

            let selectedColumn = [6, 8, 9, 10, 15, 16, 19, 21];
            selectedColumn.forEach((v) => {
              $(".c_" + v).hide();
            });

            let cl = this.$refs.colomTable.getValue();

            if (cl) {
              // cl = cl.split(",");

              cl.forEach((v) => {
                $("." + v).show();
              });
            }

            // }
          });
        });
      }
    },
    setNoUrut(idx) {
      urutan++;
      return urutan;
    },
    changeInput() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(this.cariData, 1000);
    },
    setSatker(id) {
      if (id != "all" || id == null) {
        this.G_GetRef("satker", "?balaiId=" + id).then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      } else {
        this.G_GetRef("satker").then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      }
    },
    tableCSV() {
      var workbook = XLSX.utils.book_new();

      var worksheet_data = document.getElementById("tableShow");
      var worksheet = XLSX.utils.table_to_sheet(worksheet_data);

      workbook.SheetNames.push("Progres Per Paket");
      workbook.Sheets["Progres Per Paket"] = worksheet;

      //  exportExcelFile(workbook);

      return XLSX.writeFile(
        workbook,
        "Progres Per Paket " + this.G_formatDate(Date(), "d-M-Y") + ".xlsx"
      );
      // var data = ['waw'];
      // var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      // var objectUrl = URL.createObjectURL(blob);
      // window.open(objectUrl);
    },
    tableExcel() {
      var a = document.createElement("a");
      //getting data from our div that contains the HTML table
      var data_type = "data:application/vnd.ms-excel";
      var table_div = document.getElementById("tableShow");
      var table_html = table_div.outerHTML.replace(/ /g, "%20");
      a.href = data_type + ", " + table_html;
      //setting the file name
      a.download =
        "Progres Per Paket " + this.G_formatDate(Date(), "d-M-Y") + ".xls";
      //triggering the function
      a.click();
      //just in case, prevent default behaviour
      e.preventDefault();

      // window.open('data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent( $('div[id$=tableShow]').html()));
      // e.preventDefault();
    },
    selectAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.selectAll();
      } else if (type == "giat") {
        this.$refs.giat.selectAll();
      }
    },
    removeAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.removeAll();
      } else if (type == "giat") {
        this.$refs.giat.removeAll();
      }
    },

    exportPrognosis() {
      urutan = 0;
      let myForm = document.getElementById("formCari");
      let formData = new FormData(myForm);

      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      let filter = "";

      if (this.$refs.balai.getValue()) {
        if (this.$refs.balai.getValue() != "all") {
          filter += "&kdbalai=" + this.$refs.balai.getValue();
        }
      }
      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter += "&kdsatker=" + this.$refs.satker.getValue();
        }
      }
      if (this.$refs.projek.getValue()) {
        if (this.$refs.projek.getValue() != "all") {
          if (this.$refs.projek.getValue() == "hasProyek") {
            filter += "&hasProyekOnly=true";
          } else if (this.$refs.projek.getValue() == "hasLoanOnly") {
            filter += "&hasLoanOnly=true";
          } else if (this.$refs.projek.getValue() == "hasSbsnOnly") {
            filter += "&hasSbsnOnly=true";
          } else if (this.$refs.projek.getValue() == "hasRupiahOnly") {
            filter += "&hasRupiahOnly=true";
          } else {
            filter += "&proyek=" + this.$refs.projek.getValue();
          }
        }
      }
      if (this.$refs.giat.getValue()) {
        filter += "&kdgiat=" + this.$refs.giat.getValue();
      }
      if (object.beban) {
        filter += "&beban=" + object.beban;
      }
      if (this.pgMin > 0) {
        filter += "&pgMin=" + this.pgMin * 1000;
      }
      if (this.pgMax > 0) {
        filter += "&pgMax=" + this.pgMax * 1000;
      }
      if (this.$refs.kdkategori.getValue()) {
        filter += "&kdkategori=" + this.$refs.kdkategori.getValue();
      }
      if (object.kdkppn) {
        filter += "&kdkppn=" + object.kdkppn;
      }

      let filterSelect = this.$refs.colomTable.getValue();

      if (filterSelect) {
        // filterSelect = filterSelect.split(",");
        filterSelect.forEach((v) => {
          filter += v == 8 ? "&includeRegister=true" : "&includeRegister=false";
          filter += v == 9 ? "&includeKppn=true" : "&includeKppn=false";
          filter += v == 10 ? "&includeBeban=true" : "&includeBeban=false";
          filter += v == 6 ? "&includeMetode=true" : "&includeMetode=false";
        });
      } else {
        filter += "&includeRegister=false";
        filter += "&includeKppn=false";
        filter += "&includeBeban=false";
        filter += "&includeMetode=false";
      }

      if (this.$refs.form.validate()) {
        this.$refs.btnExport.load(true);
        this.G_GetAny("progres/export-prognosis?tahun="+this.setDefaultTahun + filter).then(
          (res) => {
            var prognosis = res.data;

            window.open(
              process.env.VUE_APP_URL_API + prognosis.fileUrl,
              "_blank"
            );

            this.$refs.btnExport.load(false);
          }
        );
      }
    },
  },
};
